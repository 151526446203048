import { datadogRum } from "@datadog/browser-rum"
import { TrackingConsent } from "@datadog/browser-core"
import { Consent, consented } from "../utils/consent"

window.addEventListener("CookiebotOnConsentReady", () => {
  if (!window.datadogEnvironment) return

  datadogRum.init({
    applicationId: "00847d7d-72d5-4ede-91ac-2efdc9af7278",
    clientToken: "pubb2da50a99f90a160463bb01ac07a1019",
    site: "datadoghq.com",
    service: "market-storefront",
    env: window.datadogEnvironment,
    version: window.datadogVersion,
    sessionSampleRate: window.datadogSampleRate,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
    trackingConsent: consented(Consent.statistics) ? TrackingConsent.GRANTED : TrackingConsent.NOT_GRANTED,
  })
})
